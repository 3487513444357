.dashboard {
    background: linear-gradient(135deg, #ffffff 0%, #ffffff 20%, #4cb052 100%);
    min-height: 100vh;
  }
  
  .logo {
    max-width: 200px;
    margin-bottom: 1rem;
  }
  
  .display-4 {
    color: #4cb052;
  }
  
  .lead {
    color: #eda640;
  }
  
  .bg-transparent {
    background-color: transparent !important;
  }
  