.home {
  background: linear-gradient(135deg, #ffffff 0%, #ffffff 20%, #4cb052 100%);
  min-height: 100vh;
}

.logo {
  max-width: 200px;
  margin-bottom: 1rem;
}

.display-3 {
  color: #4cb052;
}

.lead {
  color: #eda640;
}

.bg-transparent {
  background-color: transparent !important;
}

.btn-warning {
  background-color: #eda640;
  border-color: #eda640;
  color: #ffffff;
}

.btn-warning:hover {
  background-color: #e1b32b;
  border-color: #e1b32b;
}
